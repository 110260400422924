'use strict';

var React = require("react");
var Layout = require("./Layout.js");
var PageWrapper = require("./PageWrapper.js");
var TardisPng = require("./tardis.png");

var tardis = TardisPng;

function NotFoundPage(Props) {
  return React.createElement(Layout.make, {
              children: React.createElement(PageWrapper.make, {
                    title: "NOT FOUND",
                    children: null
                  }, React.createElement("p", undefined, "Wooops - page not found."), React.createElement("img", {
                        alt: "Tardis",
                        src: tardis
                      }), React.createElement("br", undefined), React.createElement("br", undefined), React.createElement("blockquote", undefined, React.createElement("br", undefined), React.createElement("br", undefined), "People assume that time is a strict progression of cause to effect, but ", React.createElement("em", undefined, "actually"), ", from a non-linear, non-subjective viewpoint", " — it\'s more like a big ball of wibbly wobbly... time-y wimey... stuff.", React.createElement("br", undefined), React.createElement("br", undefined), " — The Doctor"))
            });
}

var make = NotFoundPage;

exports.tardis = tardis;
exports.make = make;
/* tardis Not a pure module */
